<template lang="pug">
  .press
    //- panel-left(ref="panel", :keepOpen="!panelCollapsed")
      nav.children-mt-lh(v-if="isProgramme", role="navigation")
        ul
          li(v-for="item in menu")
            flex-link.btn-theme-text.btn-theme-text--lazy(:link="item.flexLink") {{ item.flexLink.text }}

        ul
          li(v-for="feed in dateFeeds")
            router-link.btn-theme-text(:to="feed[1]") {{ feed[0] }}

        ul
          li(v-for="tag in tags")
            router-link.btn-theme-text.capitalize(:to="`/programme/tagged/${tag.slug}`") {{ tag.title }}

    //- (filter btn mbl)
    //- button.h-60.px-20.flex.w-full.items-center.md_hidden.focus_outline-none(v-if="$route.meta.filterBtn", @click="$refs.panel && $refs.panel.open()") {{ $route.path === '/' ? 'Expand Menu': 'Filter' }}

    transition(name="pagefade", @after-leave="$root.$emit('afterRouteLeave')")
      router-view(:key="$route.path")

</template>

<script>
// import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Press'
}
</script>

<style>
</style>
