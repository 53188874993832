<template lang="pug">
  main-el.programme-entry
    //- pre {{ doc }}
    article(v-if="doc")
      //- landing
      project-landing(:doc="doc")

      //- (intro)
      section.md_px-20(v-if="doc.intro")
        animate-push-on-panel.px-20.md_w-8x12.md_ml-1x12.my-50.md_mt-130.md_mb-140.children-mt-lh.text-18.md_text-20.xl_text-24(v-html="doc.intro")
      //- body
      slice-body.mt-50.md_mt-100(ref="sliceBody", :slices="doc.body", :templates="templates")
      //- footer
      footer
        slice-topics(:slice="doc.footerTopics[0]", v-if="doc.footerTopics")
        related-posts(:entries="doc.relatedPosts")
</template>

<script>
import ProjectLanding from '@/components/project/ProjectLanding'
import SliceBodyText from '@/slices/SliceBodyText'
import RelatedPosts from '@/components/project/RelatedPosts'
// project slices
import SliceProjectImage from '@/slices/SliceProjectImage'
import SliceImagesGrid from '@/slices/SliceImagesGrid'
import SliceCarousel from '@/slices/SliceCarousel'
import SliceBoardList from '@/slices/SliceBoardList'
import SliceTopics from '@/slices/SliceTopics'
import SliceCallToActionRow from '@/slices/SliceCallToActionRow'
import SliceAudioPlayer from '@/slices/SliceAudioPlayer'
import SliceVimeoYoutubePlayer from '@/slices/SliceVimeoYoutubePlayer'
import SliceScriptToggle from '@/slices/SliceScriptToggle'
// performance slices...
import SliceImageFull from '@/slices/SliceImageFull'
import SliceTextbodyImage from '@/slices/SliceTextbodyImage'
import SliceImageAccentSquare from '@/slices/SliceImageAccentSquare'
// new
import SliceEmbed from '@/slices/SliceEmbed'

export default {
  name: 'ProgrammeEntry',
  components: { ProjectLanding, SliceTopics, SliceBodyText, RelatedPosts },
  data () {
    return {
      doc: null,
      templates: {
        projectBlocks_imageGrid_BlockType: SliceImagesGrid,
        projectBlocks_bodyText_BlockType: SliceBodyText,
        projectBlocks_boardList_BlockType: SliceBoardList,
        projectBlocks_image_BlockType: SliceProjectImage,
        projectBlocks_carousel_BlockType: SliceCarousel,
        // projectBlocks_topic_BlockType: SliceTopics,
        projectBlocks_callToAction_BlockType: SliceCallToActionRow,
        projectBlocks_audioPlayer_BlockType: SliceAudioPlayer,
        projectBlocks_vimeoYoutubePlayer_BlockType: SliceVimeoYoutubePlayer,
        projectBlocks_videoPlayer_BlockType: SliceVimeoYoutubePlayer,
        projectBlocks_scriptToggle_BlockType: SliceScriptToggle,
        projectBlocks_embed_BlockType: SliceEmbed,
        projectBlocks_imageVideo_BlockType: SliceImageFull,
        //
        performanceBody_image_BlockType: SliceImageFull,
        performanceBody_bodyText_BlockType: SliceBodyText,
        performanceBody_textAndImageSquare_BlockType: SliceTextbodyImage,
        performanceBody_accentImage_BlockType: SliceImageAccentSquare,
        performanceBody_embed_BlockType: SliceEmbed,
        // NEW
        projectBlocks_textAndImageSquare_BlockType: SliceTextbodyImage,
        projectBlocks_accentImage_BlockType: SliceImageAccentSquare,
        // end new
        performanceBody_vimeoYoutubePlayer_BlockType: SliceVimeoYoutubePlayer,
        performanceBody_videoPlayer_BlockType: SliceVimeoYoutubePlayer,
        performanceBody_callToAction_BlockType: SliceCallToActionRow
      }
    }
  },
  methods: {
    async get () {
      this.doc = await this.$store.dispatch('getPressEntry', { slug: this.$route.params.entry })
    }
  },
  created () {
    this.get()
  },
  beforeRouteLeave (to, from, next) {
    // remove any artist internvention scripts...
    this.$refs.sliceBody.$children.forEach(comp => typeof comp.removeScript === 'function' ? comp.removeScript() : false)
    // load next route
    next()
  },
  metaInfo () {
    if (this.doc) {
      const div = document.createElement('div')
      div.innerHTML = this.doc.titleRich.replaceAll('<br />', ' ')
      return {
        title: div.innerText,
        meta: this.$store.getters.meta({
          title: div.innerText,
          descrip: this.doc.metaDescription,
          img: this.doc.metaImage[0]?.url || this.doc.indexImage[0]?.url
        })
      }
    }
  }
}
</script>

<style>
</style>
